import React from "react"

import {graphql} from 'gatsby'

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"

const BillPage = ({data}) => {
    const meta = data.graphAPI.contentBilling.meta
    const content = data.graphAPI.contentBilling.content
    return (
        <Layout>
            <SEO title={meta.title} />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">{meta.title}</h1>
                    </div>
                    <Content content={content} />
                    <div className="segment">
                    <div className="content-section">
                        <h2>Service Rate Schedule</h2>
                        {data.graphAPI.serviceRate.services.map(service => {
                            console.log(service.rateTiers)
                            return (
                            (<div className="service-box">
                                <h4>{service.title}</h4>
                                <table className="table" style={{width: 'auto'}}> 
                                    <tbody>
                                        {service.rateTiers.map((tier, key) => {
                                            function getRate(rate, key, service) {
                                                function numberWithCommas(x) {
                                                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                }
                                                console.log(rate.title)
                                                if(key === 0 && rate.to === -1){
                                                    if(rate.type === "fixed") {
                                                        return `Base Rate for Service`
                                                    } else if(rate.type === "minimum") {
                                                        return `Minimum Rate for Service`
                                                    } else if(rate.type === "per1K") {
                                                        return `Rate per 1,000 gallons of metered usage`
                                                    } else {
                                                        return `Undefined Rate`
                                                    }
                                                }

                                                if(rate.title !== undefined) {
                                                    if(rate.type === "fixed" || rate.type === "tax") {
                                                        return `${rate.title}`
                                                    } else if(rate.type === "per1K") {
                                                        return `Rate per 1,000 gallons of metered usage`
                                                    } else {
                                                        return `Undefined Rate`
                                                    }

                                                }

                                                if(rate.type === "per1K") {
                                                    if(rate.to === -1) {
                                                        return `Rate per 1,000 gallons metered for usage above ${numberWithCommas(rate.from)} gallons`
                                                    }
                                                    return `Rate per 1,000 gallons metered for usage between ${numberWithCommas(rate.from)} gallons and ${numberWithCommas(rate.to)} gallons`
                                                }

                                                return `Undefined Rate`


                                            }
                                            return (
                                            <tr key={key}>
                                                {tier.type === "tax" ? (
                                                    <td style={{textAlign: 'right', width: '100px'}}>{(tier.rate*100).toFixed(2)} %</td>
                                                ) : (
                                                    <td style={{textAlign: 'right', width: '100px'}}>$ {tier.rate.toFixed(2)}</td>
                                                )}
                                                <td>{getRate(tier, key, service.title)}</td>

                                            </tr>
                                        )})}

                                    </tbody>
                                </table>
                            </div>)
                        )})}

                    </div>
                    <div className="content-section">
                        <p>FBMUD 23 elected to join the Missouri City Ground Water Reduction Plan in July of 2008 to satisfy the Texas Legislative conversion mandates implemented by the Fort Bend Subsidence District.</p>

                        <p>The GRP fee assessed each month is the way the conversion mandates are funded. For more information on subsidence please visit the Fort Bend Subsidence District webpage.</p>

                        <p><a href="https://fbsubsidence.org/">https://fbsubsidence.org/</a></p>
                    </div>
                    </div>

                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default BillPage

export const billPageQuery = graphql`
    query billPage {
        graphAPI {
            contentBilling {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            serviceRate {
                services {
                    rateTiers
                    title
                    id
                }
            }
        } 
    }
`


